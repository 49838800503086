@font-face {
  font-family: "Roboto";
  font-weight: 400;
  src: local("Roboto Semilight"), local("Roboto");
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  width: 100vv;
  height: 100vh;
  overflow-y: hidden;

  .header {
    width: 100%;
    border-bottom: 1px solid gainsboro;
    text-align: center;
  }

  .loading {
    width: 100%;
    text-align: center;
  }

  .mailReader {
    width: 100%;
    display: flex;
    height: calc(100% - 25px);

    .messageBox {
      flex: 0.4;
      overflow-x: hidden;
      overflow-y: auto;
      height: 100%;
      border-right: 2px solid gainsboro;

      .message {
        width: 100%;
        padding: 2px 10px;
        border-bottom: 1px solid rgb(150, 150, 255);

        cursor: pointer;

        &:hover {
          background-color: #eeeeee;
        }

        .date {
          color: grey;
          font-size: 10px;
        }

        .from {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .subject {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 500;
        }
      }
    }

    .messageReader {
      flex: 0.6;
      overflow-x: hidden;
      overflow-y: auto;
      height: 100%;

      .loading {
        width: 100%;
        font-size: 20px;
        align-items: center;
        padding: 20px;
      }
    }
  }

  .error-box {
    background-color: rgba(255, 160, 160, 0.479);
    font-weight: bolder;
    .error-header {
      font-size: 22px;
    }
  }
}
